<template>
    <Layout>
        <PageHeader :title="title" :items="items"/>
        <div class="row" v>
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <b-overlay :show="is_load">
                            <b-form @submit="save" v-if="form && form.empresa">
                                <div class="row justify-content-center">
                                    <div class="col-12 ">
                                        <h4>Seus dados<br><span>Pessoa física</span></h4><br>


                                        <div class="row">
                                            <div class="col-md-3">
                                                <b-form-group label="CPF" class="required">
                                                    <b-form-input v-mask="['###.###.###-##']" v-model="form.pessoa.cpf" placeholder="" required></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-md-3">
                                                <b-form-group label="Nome" class="required">
                                                    <b-form-input v-model="form.pessoa.nome" placeholder="" required></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-md-3">
                                                <b-form-group label="Sobrenome" class="required">
                                                    <b-form-input v-model="form.pessoa.sobrenome" placeholder="" required></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-md-3">
                                                <b-form-group label="Nascimento" class="required">
                                                    <b-form-input v-mask="['##/##/####']" v-model="form.pessoa.nascimento" placeholder="" required></b-form-input>
                                                </b-form-group>
                                            </div>

                                            <div class="col-md-6">
                                                <b-form-group label="E-mail" class="required">
                                                    <b-form-input type="email" v-model="form.cartao.email" placeholder="" required></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-md-3">
                                                <b-form-group label="Senha" class="required">
                                                    <b-form-input v-model="form.pessoa.senha" placeholder="" required></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-md-3">
                                                <b-form-group label="Confirmação de senha" class="required">
                                                    <b-form-input v-model="form.pessoa.senha_confirmacao" placeholder="" required></b-form-input>
                                                </b-form-group>
                                            </div>
                                        </div>


                                        <div class="row">
                                            <div class="col-md-2">
                                                <b-form-group label="CEP" class="required">
                                                    <b-form-input @change="buscaCep" v-mask="['#####-###']" v-model="form.pessoa.cep" placeholder="" required></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-md-5">
                                                <b-form-group label="Endereço" class="required">
                                                    <b-form-input v-model="form.pessoa.endereco" placeholder="" required></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-md-2">
                                                <b-form-group label="Número" class="required">
                                                    <b-form-input v-model="form.pessoa.numero" placeholder="" required></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-md-3">
                                                <b-form-group label="Complemento" class="">
                                                    <b-form-input v-model="form.pessoa.complemento" placeholder=""></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-md-2">
                                                <b-form-group label="Bairro" class="required">
                                                    <b-form-input v-model="form.pessoa.bairro" placeholder="" required></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-md-2">
                                                <b-form-group label="Cidade" class="required">
                                                    <b-form-input v-model="form.pessoa.cidade" placeholder="" required></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <div class="col-md-2">
                                                <b-form-group label="Estado" class="required">
                                                    <b-form-input v-model="form.pessoa.estado" placeholder="" required></b-form-input>
                                                </b-form-group>
                                            </div>

                                            <div class="col-sm-3">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <b-form-group label="DDD" class="required">
                                                            <b-form-input v-mask="['##','###']" v-model="form.pessoa.ddd" placeholder="" required></b-form-input>
                                                        </b-form-group>
                                                    </div>
                                                    <div class="col-md-9">
                                                        <b-form-group label="Celular" class="required">
                                                            <b-form-input v-mask="['####-####','#####-####']" v-model="form.pessoa.fone" placeholder="" required></b-form-input>
                                                        </b-form-group>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-3">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <b-form-group label="DDD" class="required">
                                                            <b-form-input v-mask="['##','###']" v-model="form.pessoa.ddd" placeholder="" required></b-form-input>
                                                        </b-form-group>
                                                    </div>
                                                    <div class="col-md-9">
                                                        <b-form-group label="Telefone" class="required">
                                                            <b-form-input v-mask="['####-####','#####-####']" v-model="form.pessoa.telefone" placeholder="" required></b-form-input>
                                                        </b-form-group>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>


                                    </div>
                                    <div class="col-12 text-center">
                                        <b-button variant="outline-primary" size="lg" type="submit">Salvar cadastro</b-button>
                                    </div>
                                </div>


                            </b-form>
                        </b-overlay>
                    </div>
                </div>
            </div>
        </div>

    </Layout>
</template>

<script>

import axios from "axios";
import Vue from 'vue'
import Layout from "../layouts/main";
import PageHeader from "@/components/page-header";
import firebase from 'firebase/app';
import {isCPF, isCNPJ} from 'validation-br'

export default {
    name: "associados",
    components: {
        Layout,
        PageHeader,
    },
    data() {
        return {
            logo: null,
            is_load: false,
            form: null,
            title: "Associado",
            items: [
                {
                    text: "Home"
                },
                {
                    text: "Associado",
                    active: true
                }
            ],

            funcionariosQtdOptions: [{value: 1, text: "Até 9 Funcionários"},
                {value: 2, text: "De 10 a 49 Funcionários"},
                {value: 3, text: "De 50 a 99 Funcionários"},
                {value: 4, text: "Acima de 100 Funcionários"},],
            receitaOptions: [{value: 1, text: "Até R$ 60.000,00"},
                {value: 2, text: "De R$ 60.000,00 até R$ 360.000,00 "},
                {value: 3, text: "De R$ 360.000,01 até R$ 3.600.000,00"},
                {value: 4, text: "Acima de R$ 3.600.000,00"},
                {value: 5, text: "Prefiro não dizer"},],
            areaAtuacaoOptions: [{value: 13, text: "Alimentos e Bebidas"},
                {value: 14, text: "Arte e Antiguidades"},
                {value: 15, text: "Artigos Religiosos"},
                {value: 12, text: "Automoveis/Pecas"},
                {value: 16, text: "Bebes e Cia"},
                {value: 17, text: "Brinquedos e Games"},
                {value: 18, text: "Casa e Decoracao"},
                {value: 1, text: "Certificação Digital"},
                {value: 19, text: "Colecionaveis"},
                {value: 2, text: "Comparador de Preços"},
                {value: 20, text: "Compra Coletiva/Clubes de Compras"},
                {value: 21, text: "Construção e Ferramentas"},
                {value: 22, text: "Conteúdo Digital"},
                {value: 23, text: "Cosméticos, Perfumaria e Cuidados Pessoais"},
                {value: 3, text: "Educação e eventos"},
                {value: 24, text: "Eletrõnicos/Eletrodomésticos"},
                {value: 25, text: "Entretenimento"},
                {value: 26, text: "Esporte e Lazer"},
                {value: 27, text: "Flores, Cestas e Presentes"},
                {value: 28, text: "Fotografia"},
                {value: 29, text: "Informática"},
                {value: 4, text: "Informações de Mercado"},
                {value: 30, text: "Instrumentos Musicais"},
                {value: 31, text: "Joalheria"},
                {value: 32, text: "Livros"},
                {value: 33, text: "Loja de departamentos"},
                {value: 34, text: "Loterias e Apostas"},
                {value: 5, text: "Meios de Pagamento"},
                {value: 11, text: "Midia e Marketing Online"},
                {value: 35, text: "Moda e Acessórios"},
                {value: 36, text: "Musica"},
                {value: 10, text: "Outros"},
                {value: 37, text: "Papelaria e Escritorio"},
                {value: 38, text: "Pet Shop"},
                {value: 6, text: "Plataformas de Ecommerce"},
                {value: 7, text: "Portal"},
                {value: 39, text: "Saude"},
                {value: 40, text: "Servicos"},
                {value: 41, text: "Sex Shop"},
                {value: 8, text: "Softwares"},
                {value: 42, text: "Telefonia/Celulares"},
                {value: 9, text: "Varejo"},
                {value: 43, text: "Viagens e Turismo"}],
        };
    },
    mounted() {
        this.loadAssociados();
    },
    methods: {

        configUpload(e) {
            if (e.target.files && e.target.files[0])
                this.form.empresa.logo = e.target.files[0];
        },
        buscaCep() {
            this.is_load = true;
            var cepLocal = this.form.empresa.cep.replace(/\D/g, '');
            axios.get(`https://viacep.com.br/ws/${cepLocal}/json/`).then((response) => {
                this.is_load = false;
                console.log('response', response);
                this.form.empresa.cep = response.data.cep;
                this.form.empresa.endereco = response.data.logradouro;
                this.form.empresa.bairro = response.data.bairro;
                this.form.empresa.cidade = response.data.localidade;
                this.form.empresa.estado = response.data.uf;
            }).catch((erro) => {
                this.is_load = false;
                console.log('erro', erro)
            })
        },
        save(evt) {
            evt.preventDefault();

            if (!isCNPJ(this.form.empresa.cnpj)) {
                Vue.swal({
                    title: 'CNPJ inválido',
                    text: 'Por favor, digite um CNPJ válido para a empresa',
                    type: 'error',
                    confirmButtonText: 'Ok'
                })
            }

            if (!isCPF(this.form.pessoa.cpf)) {
                Vue.swal({
                    title: 'CPF inválido',
                    text: 'Por favor, digite um CPF válido para o contato',
                    type: 'error',
                    confirmButtonText: 'Ok'
                })
            }


            this.form.pessoa.cpf = this.form.pessoa.cpf.replace(/[^0-9]/g, '')
            this.form.empresa.cnpj = this.form.empresa.cnpj.replace(/[^0-9]/g, '')


            const formData = new FormData();
            if (this.logo) {
                formData.append('id_plano', this.form.id_plano);
                formData.append('logo', this.form.empresa.logo);
                formData.append('cnpj', this.form.empresa.cnpj);
            }


            this.is_load = true;
            axios.post('cadastrosUpdate', this.form, {headers: {"Access-Control-Allow-Origin": true}}).then((response) => {
                console.log(response)
                Vue.swal({
                    title: 'Cadastro atualizado com sucesso',
                    type: 'success',
                    confirmButtonText: 'Ok'
                }).then(() => {

                    window.location.href = '/associados'
                })
                if (!this.logo) this.is_load = false;
                if (this.logo) {
                    axios.post('uploadLogo', formData, {headers: {"Access-Control-Allow-Origin": true, 'Content-Type': 'multipart/form-data'}}).then(() => {
                        this.is_load = false;
                    }).catch((erro) => {
                        console.log('erro erro', erro)
                        this.is_load = false;
                    })
                }
            }).catch((erro) => {
                console.log('erro erro', erro)
                this.is_load = false;
                Vue.swal({
                    title: 'Erro ao atualizar cadastro',
                    text: erro.response.data.erro,
                    type: 'error',
                    confirmButtonText: 'Ok'
                })
            })


        },
        loadAssociados() {
            var search = this.$route.params.cnpj.replace(/[^0-9]/g, '');
            console.log('cnpj', search);
            this.is_load = true;
            var consulta = firebase.firestore().collection('empresas').doc(search);
            consulta.get().then(snapshot => {
                this.form = snapshot.data();
                console.log('this.form', this.form)
                console.log('form', this.form);
                this.is_load = false;
            }).catch(error => {
                console.log(error);
                this.is_load = false;
            });
        },
    }
}
</script>

<style scoped>

</style>